import React, { Component } from "react";

class Contacts extends Component {
  render() {
    return (
      <>Contacts</>
    );
  }
}

export default Contacts;